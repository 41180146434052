import {
  breadcrumb,
  DataFormClient,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { STEP_AUDIT } from "../../../const/catalog";
import { navigateNext, stepProgress } from "../../../dataBase/stepProgress";
import { StatusAuditDocument } from "../../../enum/statusAuditDocument";
import { Steps } from "../../../enum/steps";
import { PostUploadAttachments } from "../../../fnx/apaAuditDocuments/quoteUploadAttachtments";
import { GetProjectOne } from "../../../fnx/project/projectOne";
import { GetQuoteOne } from "../../../fnx/quotes/getQuoteOne";
import { showToastSuccessMessage } from "../../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../../fnx/showError";
import { RowsModel } from "../../../model/insurancePoliciesSummary";
import { formatDocument } from "../../../services/functions";
import LocalStorageService from "../../../services/localStorage";
import { getPermission } from "../../../services/nav";
import showToast from "../../../services/toast";
import {
  quoteOneClearData,
  updateStatusAuditDoc,
  updateStatusAuditDocClearData,
} from "../../../store/generateQuote/actions";
import { projectOneClearData } from "../../../store/project/actions";
import { newFormattedDate } from "../../../utils/date";
import { formatToTwoDecimals } from "../../../utils/getPrice";
import { DataForm } from "./auditPageDTO";
import { AuditPageView } from "./auditPageView";

const STEP = STEP_AUDIT;
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
export const AuditPage = () => {
  const { idQuoteParams } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger, setTrigger] = React.useState(false);
  const [uploadTrigger, setUploadTrigger] = React.useState(false);
  const [projectTrigger, setProjectTrigger] = React.useState(false);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [projectId, setProjectId] = React.useState<string>("");
  const [rows, setRows] = React.useState<any>([]);
  const [dataForm, setDataForm] = useState<DataFormClient>(DataForm);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openDrawerRejed, setOpenDrawerRejed] = React.useState<boolean>(false);
  const [fileName, setFileName] = React.useState<string>("");
  const [pdfFile, setPdfFile] = React.useState<File>();
  const [idDocument, setIdDocument] = React.useState<string>("");
  const [idQuote, setIdQuote] = React.useState<string>("");
  const [urlDocument, setUrlDocument] = React.useState<string>("");
  const [documentCode, setDocumentCode] = React.useState<string>("");
  const [attachDocuments, setAttachDocuments] = React.useState<any[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [carCondition, setCarCondition] = React.useState<string>("");
  const [filtersData, setFiltersData] = React.useState({
    Product: "",
    Plan: "",
    Payment: "",
    CoverType: "",
    Campaign: "",
    Price: "",
  });
  const [filters, setFilters] = React.useState({});
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const quoteId =
    localStorageService.getStepStored().steps[Steps.customer].quoteId;

  const permissions = getPermission();

  const hasPermission = (name: string): boolean => {
    return permissions.some((p) => p.name === name);
  };

  const permissionApproveDocument: boolean = hasPermission(
    "sales-apap-audit-actions-approved"
  );
  const permissionRejectDocument: boolean = hasPermission(
    "sales-apap-audit-actions-rejected"
  );
  const permissionDownloadDocument: boolean = hasPermission(
    "sales-apap-audit-actions-download"
  );
  const permissionUploadDocument: boolean = hasPermission(
    "sales-apap-audit-actions-upload"
  );
  const permissionViewDocument: boolean = hasPermission(
    "sales-apap-audit-actions-view"
  );

  const permissionCreate: boolean = hasPermission("sales-apap-audit-create");

  const allDocumentsApproved =
    (attachDocuments.length === 3 || attachDocuments.length === 4) &&
    attachDocuments.every((doc: any) => doc.status === "APPROVED") &&
    permissionCreate;

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = GetQuoteOne(
    trigger,
    idQuoteParams ? idQuoteParams : quoteId
  );

  const { responseOneProject, errorOneProject, loadingOneProject } =
    GetProjectOne(projectTrigger, projectId);

  const { responseUploadAttach, errorUploadAttach, loadingUploadAttach } =
    PostUploadAttachments(
      uploadTrigger,
      idQuoteParams ? idQuoteParams : quoteId,
      fileName,
      pdfFile,
      documentCode
    );

  useEffect(() => {
    setTrigger(true);
    setBreadCrumbs(stepProgress(STEP, true));
  }, []);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorOneProject) {
      showErrorToast(errorOneProject, setSeeToast, t);
    }
  }, [errorOneProject]);

  useEffect(() => {
    if (errorUploadAttach) {
      showErrorToast(errorUploadAttach, setSeeToast, t);
    }
  }, [errorUploadAttach]);

  useEffect(() => {
    if (responseOneProject) {
      setProjectRows(
        responseOneProject?.salesFlow?.metadata?.navigation?.flowStep
          ?.steps?.[6]?.documents?.[carCondition === "USED" ? "used" : "new"] ||
          []
      );
    }
  }, [responseOneProject]);

  const getCombustionLabel = (combustionType: any) => {
    switch (combustionType) {
      case "ELECTRIC":
        return "Eléctrico";
      case "GASOLINE":
        return "Gasolina";
      case "DIESEL":
        return "Diésel";
      case "GAS":
        return "Gas";
      default:
        return combustionType;
    }
  };

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      setDataForm({
        brand: responseOneQuote.data?.contentMap?.brand.name,
        model: responseOneQuote.data?.contentMap?.model.name,
        carColor: responseOneQuote.data?.contentMap?.colors.name,
        IdType: responseOneQuote.data?.identificationTypeId?.id,
        chassisNumber: responseOneQuote.data?.contentMap?.chassisNumber,
        numberCylinders: responseOneQuote.data?.contentMap?.cylinders,
        fuelType: getCombustionLabel(
          responseOneQuote.data?.contentMap?.combustion
        ),
        registrationNumber: responseOneQuote.data?.contentMap?.plate,
        carPrice: `RD$ ${formatToTwoDecimals(
          responseOneQuote.data?.contentMap?.deductibleTypeId || "0"
        )}`,
        //
        Type: responseOneQuote.data?.identificationTypeId?.description,
        DocumentNumber: formatDocument(responseOneQuote.data?.numberId),
        Name: responseOneQuote.data?.firstNameA,
        SecondName: responseOneQuote?.data?.firstNameB,
        FirstLastName: responseOneQuote?.data?.lastNameA,
        SecondLastName: responseOneQuote?.data?.lastNameB,
        MarriedSurName: responseOneQuote?.data?.marriedName,
        Gender: responseOneQuote.data?.catalogGender?.description,
        Birthday: responseOneQuote?.data?.dob,
        //
        Country: responseOneQuote?.data?.nationality?.description,
        Province: responseOneQuote?.data?.province?.name,
        Town: responseOneQuote?.data?.municipality.name,
        Sector: responseOneQuote?.data?.district?.name,
        PostalCode: responseOneQuote?.data?.zip,
        Address1: responseOneQuote?.data?.address1,
        Address2: responseOneQuote?.data?.address2,
        //
        PhoneHome: responseOneQuote.data?.phone1,
        PhoneOffice: responseOneQuote.data?.phone2,
        PhoneNumber: responseOneQuote?.data?.phone3,
        Email: responseOneQuote.data?.email,
      });
      setProjectId(responseOneQuote?.data?.productId.id);
      setProjectTrigger(true);
      setIdQuote(responseOneQuote?.data?.id);
      setCarCondition(responseOneQuote?.data?.contentMap?.condition);
      if (responseOneQuote?.data?.attachments.length > 0) {
        const documentsGroupedByCode =
          responseOneQuote?.data?.attachments.reduce((acc: any, item: any) => {
            acc[item.code] = acc[item.code]
              ? [...acc[item.code], item]
              : [item];
            return acc;
          }, {});
        const lastDocumentUploaded = Object.keys(documentsGroupedByCode).map(
          (code) => {
            const items = documentsGroupedByCode[code];
            return items[items.length - 1];
          }
        );

        setAttachDocuments(lastDocumentUploaded);
      }
      /*Adiciona al resumen la informacion de vehiculos*/
      let carConditionName;
      switch (responseOneQuote?.data?.contentMap?.condition) {
        case "USED":
          carConditionName = t(`carDetails.carCondition.usado`);
          break;
        case "NEW":
          carConditionName = t(`carDetails.carCondition.nuevo`);
          break;
      }
      setFilters({
        Product: responseOneQuote?.data?.productId.description,
        Plan: responseOneQuote?.data?.planId.name,
        Payment: responseOneQuote?.data?.catalogPaymentMode?.description,
        CoverType: responseOneQuote?.data?.coverType?.description,
        Campaign: responseOneQuote?.data?.campaignId?.name,
        Price: `${
          responseOneQuote?.data?.lastPrice
            ? responseOneQuote?.data?.lastPrice?.currencySymbol
            : "RD$"
        } ${
          responseOneQuote?.data?.lastPrice
            ? formatToTwoDecimals(responseOneQuote?.data?.lastPrice?.amount)
            : "0.00"
        }`,
        year: responseOneQuote?.data?.contentMap?.year,
        model: responseOneQuote?.data?.contentMap?.model?.name,
        brand: responseOneQuote?.data?.contentMap?.brand?.name,
        chassis: responseOneQuote?.data?.contentMap?.chassisNumber,
        plate: responseOneQuote?.data?.contentMap?.plate,
        statusVehicle: carConditionName,
      });
      setTrigger(false);
      dispatch(quoteOneClearData());
      dispatch(projectOneClearData());
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseUploadAttach) {
      setTrigger(true);
      setOpenDrawer(false);
    }
  }, [responseUploadAttach]);

  useEffect(() => {
    if (attachDocuments.length === 0) return;

    const updatedRows = rows.map((row: any) => {
      const matchingDocument = attachDocuments.find(
        (doc) => doc.code === row.code
      );

      if (!matchingDocument) return row;

      return {
        ...row,
        id: matchingDocument.id,
        state: getStateFromStatus(matchingDocument.status),
        date: newFormattedDate(matchingDocument.createdAt),
        url: matchingDocument.filePath,
        comments:
          matchingDocument?.comments && matchingDocument?.comments[0]?.comment,
      };
    });

    if (!areRowsEqual(rows, updatedRows)) {
      setRows(updatedRows);
    }
  }, [attachDocuments, rows]);

  const getStateFromStatus = (status: string): string => {
    switch (status) {
      case "PENDING":
        return "Pendiente";
      case "REJECTED":
        return "Rechazado";
      default:
        return "Aprobado";
    }
  };

  const areRowsEqual = (rows1: any[], rows2: any[]): boolean => {
    return JSON.stringify(rows1) === JSON.stringify(rows2);
  };

  const handleClickContextMenu = (item: any, params: any) => {
    switch (item.id) {
      case "1":
        setDocumentCode(params.row.code);
        setOpenDrawer(true);
        break;
      case "2":
        setOpenModal(true);
        setIdDocument(params.row.id);
        setUrlDocument(params.row.url);
        // navigate(
        //   `/visor-pdf/${params.row.id}/${
        //     idQuoteParams ? idQuoteParams : quoteId
        //   }`,
        // );
        localStorageService.setStepStored({
          id: "",
          name: "",
          step: Steps.auditDocumentUrl,
          completed: true,
          auditDocumentUrl: params.row.url,
        });
        break;
      case "3":
        console.log("case3", item, params.row);
        break;
      default:
        break;
    }
  };

  const setProjectRows = (responseOneProject: RowsModel[]) => {
    const DocumentRows = responseOneProject?.map(
      (item: RowsModel, index: number) => ({
        id: index.toString(),
        code: item.code,
        docType: item.document,
        state: item.state ? item.state : "Sin documento",
        date: item.dateUpdated,
        user: item.user,
        comments: item.comentario,
      })
    );
    setRows(DocumentRows);
    dispatch(projectOneClearData());
  };

  const quoteUploadAttachments = (file: File[]) => {
    if (file) {
      setFileName(file[0].name.replace(".pdf", ""));
      setPdfFile(file[0]);
      setUploadTrigger(true);
    }
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const onClickButtonBack = () => {
    if (idQuoteParams) {
      navigate("/insurance-policies-summary");
    }
  };
  const onClickNext = () => {
    navigateNext(STEP, navigate);
  };
  /////
  const {
    responseUpdateStatusAuditDocs,
    errorUpdateStatusAuditDocs,
    loadingUpdateStatusAuditDocs,
  } = useSelector((state: any) => ({
    responseUpdateStatusAuditDocs: state.Quote.responseUpdateStatusAuditDocs,
    errorUpdateStatusAuditDocs: state.Quote.errorUpdateStatusAuditDocs,
    loadingUpdateStatusAuditDocs: state.Quote.loadingUpdateStatusAuditDocs,
  }));

  useEffect(() => {
    dispatch(updateStatusAuditDocClearData());
  }, []);

  useEffect(() => {
    if (errorUpdateStatusAuditDocs) {
      showErrorToast(errorUpdateStatusAuditDocs, setSeeToast, t);
    }
  }, [errorUpdateStatusAuditDocs]);

  useEffect(() => {
    if (responseUpdateStatusAuditDocs) {
      setTrigger(true);
      showToastSuccessMessage(
        "Proceso ejecutado con éxito",
        typeAlert.success,
        setSeeToast
      );
      setOpenModal(false);
      dispatch(updateStatusAuditDocClearData());
    }
  }, [responseUpdateStatusAuditDocs]);

  const updateStatusAuditDocument = (
    status: StatusAuditDocument,
    comment?: string
  ) => {
    const body = {
      comment: comment,
    };
    dispatch(
      updateStatusAuditDoc({
        quoteId: idQuote,
        documentId: idDocument,
        status: status,
        body: body,
      })
    );
  };

  const applyValue = (data: any) => {
    updateStatusAuditDocument(
      StatusAuditDocument.REJECTED,
      data.descriptionValue.value
    );
    setOpenDrawerRejed(false);
  };

  //////

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <AuditPageView
        title={"Fiscalización"}
        loading={loadingOneQuote || loadingUploadAttach || loadingOneProject}
        breadCrumbs={breadCrumbs}
        filtersData={filters}
        handleClickContextMenu={handleClickContextMenu}
        quoteId={quoteId}
        dataForm={dataForm}
        rows={rows}
        openDrawer={openDrawer}
        quoteUploadAttachments={quoteUploadAttachments}
        onCloseDrawer={onCloseDrawer}
        attachDocuments={attachDocuments}
        onBack={onClickButtonBack}
        allDocumentsApproved={allDocumentsApproved}
        onClickNext={onClickNext}
        setOpenModal={setOpenModal}
        openModal={openModal}
        ////
        openDrawerRejed={openDrawerRejed}
        setOpenDrawerRejed={setOpenDrawerRejed}
        titleDrawer={"Rechazar documento"}
        txtLegendDrawer={
          "Razón del rechazo: Es obligatorio proporcionar una explicación clara del motivo del rechazo del documento."
        }
        titleButtonDrawer={"Rechazar"}
        placeHolderDrawer={
          "Escriba una descripcion para sustentar el rechazo del documento"
        }
        subTitleDrawer={"Formulario conocimiento persona fisica"}
        onValueApply={applyValue}
        approveDocument={updateStatusAuditDocument}
        t={t}
        url={urlDocument}
        permissionApproveDocument={permissionApproveDocument}
        permissionRejectDocument={permissionRejectDocument}
        permissionDownloadDocument={permissionDownloadDocument}
        permissionUploadDocument={permissionUploadDocument}
        permissionViewDocument={permissionViewDocument}
      />
    </>
  );
};
